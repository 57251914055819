import React from 'react';
import Modal from '../../../components/Modal';
import Quote from '../../../components/Quote';

const media = {
  secTitle: 'The “Who-You-Know” Economy',
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/F-gqAS1LZx0',
  },
};

export default () => (
  <Modal closeTo="Learned" media={media}>
    <p className="Title--2 Title--c">
      People want financial security, but the barriers to accessing good work
      look different in every city. It is often the unfair influence of the “who
      you know” economy, and the numerous effects of racial discrimination and
      exclusion in the workplace that diminish access to true economic
      opportunity.
    </p>
    <p>
      Our interviewees described how much a good job means to an individual, a
      family, and a community. Even where a community’s economy was doing well,
      interviewees discussed the unintended consequences of having to work
      multiple jobs to get by. Residents point to the limited upward mobility
      they experience, and the importance of ZIP code and personal networks in
      determining who gets ahead. The perception that “who you know” is a major
      predictor of who gains access to jobs and economic opportunity reinforces
      the perceived power of the exclusive “good old boys club,” which favors
      white men with money to spend and connections.
    </p>
    <p>
      Many black residents recounted experiences of employment discrimination.
      Their experiences suggest that people of color and low-income individuals
      in professional settings risk being tokenized and often face overt racial
      or class discrimination at work. Furthermore, the following are seen as
      key barriers to access to economic opportunity: racial discriminaton,
      education/skill level, networks (who you know), economic/workforce
      development efforts, a lack of diverse industry, and low wages.
    </p>
    <p>
      Black-white wage gaps are only growing larger. As of 2015, relative to the
      average hourly wages of white men with the same education, experience,
      metro status, and region of residence, black men make 22.0 percent less,
      and black women make 34.2 percent less. Generally speaking, black women
      earn 11.7 percent less than their white female counterparts. Black men’s
      average hourly wages were 22 percent lower than those of white men in
      1979, and by 1985 the gap had grown to 27 percent. Men’s black-white wage
      gaps remained fairly stable from 1985 through much of the 2000s, gradually
      trending upward to 31 percent by 2015.
    </p>
    <Quote
      quote="We talk about the good old boys club. And basically, this system that if you’re not related to a person who already has a position of power or have certain connections to them, you kiss their ring, you have allegiance to them, you can’t get anywhere."
      person="Black female, 30"
      title="Charleston"
    />
    <Quote
      quote="You can go get your master’s or get your PhD, but at the end of the day, it kinda is now all of who you know."
      person="Black male"
      title="Richmond"
    />
    <Quote
      quote="You shouldn’t have to kill yourself just to survive."
      person="White male"
      title="Louisville"
    />
    <Quote
      quote="Mainly, the have-nots are people who live in what we call the inner city. They have a high school diploma or not. Most don’t have education beyond that. And, you know, when I was growing up, it used to be you could graduate from high school and get a job at one of the plants, so you could get a job that would put you in the middle class right out of high school. Those jobs don’t exist anymore."
      person="Black female, 66"
      title="Knoxville"
    />
    <Quote
      quote="You don’t know whether it’s because you were not qualified or because [you’re] Black. That’s always a question."
      person="Black female, 66"
      title="Richmond"
    />
  </Modal>
);
